<template>
  <div>
    <loading-more :fetch-data="fetchData" />
  </div>
</template>

<script>
import LoadingMore from "./components/loading-more";
import { getNewsList } from "@/api/product";

export default {
  components: {
    LoadingMore,
  },
  methods: {
    async fetchData(productId, page) {
      return (await getNewsList({ productId, page: { page } })).data;
    },
  },
};
</script>